interface Environment {
    REACT_APP_S64_API_URL: string;
    REACT_APP_BUILD: string;
    REACT_APP_MUI_X_PREMIUM_LICENSE_KEY: string;
}

declare global {
    interface Window {
        customEnvironmentKeys: Record<string, string>;
    }

    interface Process {
        env: Record<string, string>;
    }
}

const NotSet = (environmentKey: string) =>
    `<< Environment Key: ${environmentKey} NOT SET >>'`;

export const getEnvironmentVariable = (environmentKey: keyof Environment) => {
    return (
        process.env[environmentKey] ||
        window?.customEnvironmentKeys?.[environmentKey] ||
        NotSet(environmentKey)
    );
};

export const BaseUrl = getEnvironmentVariable('REACT_APP_S64_API_URL');
export const MuiXPremiumLicenseKey = getEnvironmentVariable(
    'REACT_APP_MUI_X_PREMIUM_LICENSE_KEY'
);
